import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import React from "react"
import "../assets/components/bootstrap/css/bootstrap.min.css"
import "../assets/scss/theme.scss"
import Layout from "../components/layout"
import SEO from "../components/seo"

const Compliance = () => {
  const query = useStaticQuery(graphql`
    query {
      cert: file(relativePath: { eq: "Instanto_Cert.png" }) {
        publicURL
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="PCI Compliance" />

      <main className="main-container">
        <section id="pci-compliance" className="section p-4 pb-lg-16 py-lg-0">
          <div className="container">
            <div className="row px-md-8">
              <div className="col-12 text-center">
                <h2 className="mt-12 mt-lg-24 mb-4 px-lg-18 ">
                  Instant Payment Technology Sweden AB is a certified Level 1
                  Service Provider for PCI DSS for Internet/e-commerce &amp;
                  Merchant Services
                </h2>
              </div>

              <div className="col-12 text-center my-2 hov">
                <Img fluid={query.cert.childImageSharp.fluid} />
              </div>
            </div>
          </div>
        </section>

        <section id="contact" className="section z-1 p-0 pb-8 custom-footer">
          <div className="container">
            <div className="row pt-lg-5 pb-lg-7 pb-6">
              <div className="col-12 bg-white" style={{ height: 2 }}></div>
            </div>
            <div className="row">
              <div className="col-12 text-center mb-3">
                <img
                  src="/images/Instanto_logo_NEG.svg"
                  alt=""
                  style={{ height: 24 }}
                />
              </div>
              <div className="col-12 text-center">
                <div className={`fs-md fw-bold`}>
                  Want to know more about Instanto? Get in touch with us on{" "}
                  <a
                    className="fw-bold text-white"
                    href="mailto:connect@instanto.eu"
                  >
                    connect@instanto.eu
                  </a>
                  .
                </div>

                <div className="fs-sm pt-1 mb-8">
                  &copy; Copyright 2020 Instant Payment Technology Sweden AB.
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </Layout>
  )
}

export default Compliance
